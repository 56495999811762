import React from 'react'
import Helmet from 'react-helmet'
import { Container } from 'reactstrap'
import Layout from '../components/layout'
import Link from 'gatsby-link'

import imgA from '../assets/images/sandwich.jpg'
import imgB from '../assets/images/slider_a.png'
import panorama from '../assets/images/panorama.png'
const AboutPage = () => {
  return (
    <Layout>
        <Helmet>
            <title>Energy Bars | "Σπιτικό Παπακωνσταντίνου"</title>
            <meta name="description" content="Home made like energy bars by Spitiko Papakonstantinou" />
        </Helmet>
      <Container className="company">
      <h1><b>Σπιτικό Παπακωνσταντίνου</b></h1>
        <p>Our company was established in 2002, aiming to offer high quality products and good service.</p>
        <p>Providing the experience, specialisation and knowledge of its executives, the company wishes to be a helper at every step of the business effort, providing even and efficient solutions. Certified according to <b>ISO 22000</b>, food safety is considered as one of the the most important advantages of all our partners.</p>
        <img src={panorama} className="fullImage" alt="THE COMPANY"></img>
        <h3><b>Energy Bars</b></h3>
        <div class="row">
        <div class="col-md-6"><img className="fullImage" src={imgB} alt="BARS"></img></div>
        <div class="col-md-6"><p>The lifestyle with increased hours and few hours of leisure time, causes many people to turn to eating healthy snacks that meet the requirements for healthy, high energy and low glycemic products.
      </p><p>Spitiko means “Homemade” handmade bars are the ideal healthy snack for children's nutrition, for people who are sporting and for those who follow a balanced diet.
      </p><p>Our bars are the ideal snack for breakfast, evening or afternoon and the advantages: energy-rich diets contain low-calorie antioxidants without added sugar and preservatives, low glycemic load, low saturated fat.
      No baked.... Based on oat and honey...
 </p>
 
 <p> <Link to="/files/EnergyBars-EN.pdf"
          onClick={(event) => { event.preventDefault(); window.open('/files/EnergyBars-EN.pdf'); }}>
          Click here to download our Energy Bars catalog.</Link></p></div>
        </div>

         <h3><b>Sandwiches</b></h3>
        <div class="row">
        <div class="col-md-6"><p>
        Our products are more than just sandwiches ... Our products are traditional bakery products and not industrial.</p>
        <p>The meat products and cheeses, we use are of excellent quality. All of our materials are certified products, which are regularly tested with microbiological analyzes, as well as our final products to ensure their quality.</p><p><b>We provide day-to-day delivery to supermarkets, internet stores, canteens, vending machines as well as sports and cultural operators</b>.
        </p></div>
        <div class="col-md-6"><img className="fullImage" src={imgA} alt="SANDWICHES"></img></div>
        </div>
      </Container>
    </Layout>
  )
}

export default AboutPage